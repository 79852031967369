console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
let actualHour = new Date();
console.log(actualHour.getTime());
const homeTopSlides = {
  slides: [
    {
      caption: "Resorts For Everyone",
      link: "/deals/uk-offers/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_RFE_HPBanner_Desktop.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "All-New Beachfront Villas & Penthouse Collection",
      link: "/resorts/negril/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_New-Accommodations-HP.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "3 New Resorts In Jamaica",
      link: "/jamaica/opening-new-resorts/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/3NewJamaicaResorts/July2021/3NewJamaicaResorts-Desktop.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "World’s Leading All-Inclusive Family Resorts",
      link: "/deals/uk-offers/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_EU_DesktopBanner_v2.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },

    // {
    //     caption: 'World’s Leading All-Inclusive Family Resorts',
    //     link: '/deals/uk-offers/',
    //     img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_EU_DesktopBanner_flip.jpg',
    // 	ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
    //     customizeLayout: false
    // },
    // {
    //     caption: 'Beaches Sale Now On',
    //     link: '/deals/uk-offers/',
    //     img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_EU_DesktopBanner.jpg',
    //     ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
    //     customizeLayout: false
    // },
    // {
    //     caption: 'We are open',
    //     link: '/grand-reopening/',
    //     img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/we-are-open-bbo.jpg',
    //     ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
    //     customizeLayout: false
    // },

    // {
    //     caption: 'Our Best Offers',
    //     link: '/deals/uk-offers/',
    //     img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_Post-BF-offers_WebBanner_1920x1080.jpg',
    //     ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
    //     customizeLayout: false
    // },
    // {
    //   caption:
    //     "Put your mind at ease thanks to our Beaches Platinum Protocols of Cleanliness™.",
    //   link: "/cleanliness-protocols/",
    //   img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_PPOC_WebBanner_1920x1080.jpg",
    //   ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
    //   customizeLayout: false,
    // },
    // {
    //   caption:
    //     "We are bringing the Beaches Resorts to a brand new destination, Saint Vincent and the Grenadines!",
    //   link: "/st-vincent-grenadines/",
    //   img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/stvincent.jpg",
    //   ibmClickTag: "HP Top Carousel-_-St.Vincent-_-N/A",
    //   customizeLayout: false,
    // },
    // {
    //     caption: 'Looking for the perfect getaway for you and your family? Discover our resorts that define the Caribbean',
    //     link: '/grand-reopening/',
    //     img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/beaches-nearly-time-hp-banner.jpg',
    //     ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
    //     customizeLayout: false
    // },
    // {
    //     caption: 'Complimentary* single-camera livestream service for your wedding ceremony when you book September 15 - December 31, 2020',
    //     link: '/weddings/livestream/',
    //     img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_LiveWedding_WebBanner_1920x1080.jpg',
    //     ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
    //     customizeLayout: false
    // },
    {
      caption: "Something to look forward to in 2023 and beyond.",
      link: "https://booking.beaches.co.uk/search",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_Brand-you-can-trust-_WebBanner_1920x1080.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: " ",
      link: "/celebrations/promotions/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/sweetheartDeal_2024_desk2.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },


    // {
    //   caption:
    //     "In Loving Memory Of The Honourable Gordon 'Butch' Stewart <br> July 6, 1941 - January 4, 2021",
    //   link: "/honoring-gordon-butch-stewart/",
    //   img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/honoring-butch-final.jpg",
    //   ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
    //   customizeLayout: false,
    // },

    /*
            // old sliders removed 21 oct 20

        {
            caption: 'Discover our Autumn Offers and save up to 45%, plus up to an extra £600 off your Caribbean holiday. Book by 9th November!',
            link: '/deals/uk-offers/ ',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_AutumnOffers_WebBanner_23Sep20.jpg',
            ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
            customizeLayout: false
        },
        // {
        //     caption: 'Beaches Turks & Caicos Reopening Soon!',
        //     link: '/resorts/turks-caicos/ ',
        //     img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/btc-countdown-reopening/background.jpg',
        //     ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
        //     additionalElements: '<a href= \'/resorts/turks-caicos/\' class=\'container\'><span class=\'text\'><span class=\'text-01\'>beaches</span><span class=\'text-02\'>Turks & Caicos</span><hr><span class=\'text-03\'>Providenciales, Turks & Caicos</span></span><span class=\'countdown\'><span class=\'text-01\'>Paradise reopens in:</span><span id=\'countdown-slide_target\'></span></span></a>',
        //     customizeLayout: true
        // },

        {
            caption: 'Complimentary* single-camera livestream service for your wedding ceremony when you book September 15 - December 31, 2020',
            link: '/weddings/livestream/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/live-stream-wedding.jpg',
            ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
            customizeLayout: false
        },


        {
            caption: 'We are bringing the Beaches Resorts to a brand new destination, Saint Vincent and the Grenadines!',
            link: '/st-vincent-grenadines/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/stvincent.jpg',
            ibmClickTag: 'HP Top Carousel-_-St.Vincent-_-N/A',
            customizeLayout: false
        },
        {
            caption: 'Looking for the perfect getaway for you and your family? Discover our resorts that define the Caribbean',
            link: '/grand-reopening/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/beaches-nearly-time-hp-banner.jpg',
            ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
            customizeLayout: false
        },
        // {
        //     caption: '10% off for our NHS Heroes',
        //     link: '/specials/uk-nhs-discount/ ',
        //     img:
        //         '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_NHS_WAITT_07April20.jpg',
        //     ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A'
        // },

        // {
        // 	caption: 'Find out how our very own resorts define the Caribbean.',
        // 	link: '/theresortsthatdefinethecaribbean/',
        // 	img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_Define_Homepage_05Apr19_02.jpg',
        // 	ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A'
        // },
        {
            caption: 'We&apos;re proud to announe we&apos;ve won the 2019 Excellence Award in the Accessible & Special Initiatives Category!',
            link: '/awards/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_FamilyTraveller_Homepage_16Jan19.jpg',
            ibmClickTag: 'HP Top Carousel-_-Beaches Awards-_-N/A',
            customizeLayout: false
        },
        //		{
        //			caption: 'Our Big Sale has arrived - book by 4 February and enjoy our fantastic New Year savings',
        //			link: '/deals/uk-offers/',
        //			img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_TheBigSale_Homepage.jpg',
        //			ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A'
        //		},
        {
            caption: 'We&apos;re thrilled to announce that we&apos;ve been awarded the Feefo Trusted Service Award!',
            link: '/awards/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_Feefo_Homepage_30jan20v2.jpg',
            ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
            customizeLayout: false
        },
        {
            caption: 'Our Featured Resort of the month is Beaches Turks & Caicos! Click to find out more about our biggest resort and why it’s a family favourite.',
            link: '/resorts/turks-caicos/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_BTC_07oct19.jpg',
            ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
            customizeLayout: false
        },
        // {
        // 	caption: 'Book any qualifying Beaches holiday of 6-nights or more and receive a PADI® Discover Scuba Diving Experience for the whole family!',
        // 	link: '/padi-dive-free/',
        // 	img: 'https://cdn.sandals.com/sandals/v12/images/home/top-slides/dive-for-free-padi-homepage-banner.jpg',
        // 	ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A'
        // },
        {
            caption: 'Quality Premium Liquors.',
            link: '/all-inclusive/premium-drinks/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/premium-liquors.jpg',
            ibmClickTag: 'HP Top Carousel-_-Define_Homepage-_-N/A',
            customizeLayout: false
        },
        {
            caption: 'Sandals Foundation - Celebrating A Decade Of Giving Back To The Caribbean',
            link: '/about/sandals-foundation/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/index-sandals-foundation.jpg',
            ibmClickTag: 'HP Top Carousel-_-Beaches Awards-_-N/A',
            customizeLayout: false
        },
        // {
        // 	caption: 'Say “¡Hola!” to Rosita – the Newest Bilingual Member of the Beaches Resorts Sesame Street<sup>®</sup> Family!',
        // 	link: '/activities/sesame-street/',
        // 	img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/sesame-street-rosita-header.jpg',
        // 	ibmClickTag: 'HP Top Carousel-_-Beaches Awards-_-N/A'
        // },
        // {
        // 	caption: 'Real Madrid Foundation Football Clinics at Beaches Negril and Beaches Turks & Caicos',
        // 	link: '/liv-plus/real-madrid-clinic/',
        // 	img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/madrid-clinic.jpg',
        // 	ibmClickTag: 'HP Top Carousel-_-Beaches_RealMadrid-_-N/A'
        // },
        {
            caption: 'Explore our endless luxury inclusions for the whole family at Beaches Resorts.',
            link: '/all-inclusive/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_MoreQualityInclusions_Homepage_16Jan19.jpg',
            ibmClickTag: 'HP Top Carousel-_-Beaches_MoreQualityInclusions-_-N/A',
            customizeLayout: false
        },
        
        {
            caption: "We have the Caribbean's best waterparks - in resort! Click here to find out more.",
            link: '/activities/waterparks/',
            img: '//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/waterpark.jpg',
            ibmClickTag: 'HP Top Carousel-_-Beacheswaterpark-_-N/A',
            customizeLayout: false
        } */
  ],
};

module.exports = homeTopSlides;
