import 'jquery-countdown';
import moment, { now } from 'moment';

const $countDown = $('.js-dsc-count');
let startDate = beaches_app.page.countdown;
let endTime = "23:59";

let newDate = new Date("2024-08-31");


// let date = moment(startDate, 'YYYY-MM-DD').add(1, 'days');

if (moment(startDate + ' ' + endTime).isBefore(moment(newDate))) {
   startDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
    endTime = "23:59";
}

const date = moment(startDate + ' ' + endTime, "YYYY-MM-DD HH:mm");
console.log('newDate: '+newDate);
// if (moment(date).isBefore(moment())) {
//   date = moment().add(2, 'days');
// }


$countDown.countdown(date.format('YYYY-MM-DD') + ' ' + endTime, (event) => {
  let days = event.strftime('%D');

  if (days === '01') {
    days = 'hurry! Only ' + days + ' day left!';
  } else if (days === '00') {
    days = 'hurry! Last day to save!';
  } else {
    days = 'hurry! Only ' + days + ' days left!';
  }

  $('.daysLeft').html(days);

  $(event.currentTarget).html(event.strftime(''
    + '<div><span class="count-digits">%D</span> days</div>'
    + '<div><span class="count-digits">%H</span> hours</div>'
    + '<div><span class="count-digits">%M</span> min.</div>'
    + '<div><span class="count-digits">%S</span> sec.</div>'));
});
