import 'slick-carousel';
import '~/js/sections/root/includes/home-top-slider';
import '~/js/sections/root/includes/resort-slider';
// import '~/js/sections/root/includes/suite-slider';
import '~/js/sections/root/includes/inclusion-slider';
import '~/js/sections/root/includes/new-at-beaches-slider';
import '~/js/modules/countdown';
// import QuickQuote from '~/js/components/global/QuickQuote';

const list = document.getElementById('list');
const marginTarget = document.querySelector('#newSandals .sectionCopy');
// const mmQuickQuote = document.querySelector('.rct-qq-resort-menu.landing');
const whyBookSandals = document.querySelector('.detail-book');
const detailClose = document.getElementById('detailClose');
const sliderWraps = Array.from(document.getElementsByClassName('pausedSlider'));

const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();

  return bounding.top >= -650 && bounding.left >= 0 && bounding.bottom <= (window.innerHeight + 650 || document.documentElement.clientHeight + 650) && bounding.right <= (window.innerWidth || document.documentElement.clientWidth);
};


const autoPlayVideos = () => {
  // Logic for Autoplay videos
    const sPageURL = decodeURIComponent(window.location.search.substring(1));
    const sURLVariables = sPageURL.split('&');
    const vars = [];
    const sSelector = 'a.js-magnific-video.autoplay-on';

    // let $autoPlay;
    let $toggle;

    vars.autoplay = sURLVariables[0].split('=');
    const $autoPlay = vars.autoplay[1] === undefined ? false : vars.autoplay[1];

    if (sURLVariables.length > 1) {
        vars.video = sURLVariables[1].split('=');
        $toggle = vars.video[1];
    }

    if ($autoPlay[1]) {
      if (sURLVariables.length > 1 && vars.video[0] === 'video') {
          document.getElementById($toggle).click();
      } else {
          document.querySelector(sSelector).click();
      }
  }

    // console.log(vars + ' toggle: ' + $toggle + ' sSelector :' + sSelector + ' param: ' + vars.video[0]);
}

$(document).ready(() => {
  autoPlayVideos();
});

const playSlide = _.debounce(() => {
  sliderWraps.forEach(slider => {
    if (window.innerWidth > 767) {
      if (isInViewport(slider) && (slider.getAttribute('data-played') === 'false')) {
        slider.setAttribute('data-played', 'true');
        $(slider).removeClass('pausedSlider');
        $(slider).slick('slickPlay');
      }
    }
    $(slider).find('.slick-slide[data-slick-index=0]').find('.slide-image').attr('src', $(slider).find('.slick-slide[data-slick-index=0]').find('.slide-image').data('src'));
    $(slider).find('.slick-slide[data-slick-index=1]').find('.slide-image').attr('src', $(slider).find('.slick-slide[data-slick-index=1]').find('.slide-image').data('src'));
    $(slider).find('.slick-slide[data-slick-index=-1]').find('.slide-image').attr('src', $(slider).find('.slick-slide[data-slick-index=-1]').find('.slide-image').data('src'));
  });
}, 300);

const adjustMargin = _.debounce(() => {
  marginTarget.style.marginTop = `${list.offsetHeight / 3}px`;
  list.style.marginTop = `-${list.offsetHeight / 3}px`;
}, 1000);

if (!!marginTarget) {
  marginTarget.style.marginTop = `${list.offsetHeight / 3}px`;
}
if (!!list) {
  list.style.marginTop = `-${list.offsetHeight / 3}px`;
}

window.addEventListener('scroll', playSlide);

window.addEventListener('resize', adjustMargin);

// ReactDOM.render(
//   <QuickQuote
//     schedulerModeIndex={4}
//     hasCountries={false}
//     hasResorts
//     brand="beaches"
//     noLabels
//     quoteButtonText="CHECK RATES <i class='icon-link-arrow-beaches'></i>"
//     occupancyLabels={false}
//     startDatePlaceholderText="Check In"
//     endDatePlaceholderText="Check Out"
//     adultsDropdownPlaceHolder="<span class='occupancy-placeholder'>Adults<span class='display-block'>(Age 16+)</span></span>"
//     childrenDropdownPlaceHolder="<span class='occupancy-placeholder'>Kids<span class='display-block'>(Age 0-15)</span></span>"
//   />,
//   mmQuickQuote
// );

document.onclick = (event) => {
  const target = event.target;

  if (!Element.prototype.matches) {
    if (target.webkitMatchesSelector) {
      target.matches = target.webkitMatchesSelector;
    }

    if (target.msMatchesSelector) {
      target.matches = target.msMatchesSelector;
    }

    if (target.mozMatchesSelector) {
      target.matches = target.mozMatchesSelector;
    }
  }
  if (target.matches('.whyBook, .whyBook *')) {
    if (!whyBookSandals.classList.contains('show')) {
      whyBookSandals.classList.add('show');
    }
  }

  if (target === detailClose) {
    whyBookSandals.classList.remove('show');
  }
};


// form to /sweepstakes/
// function wrongEmail(input) {
//   const error = document.querySelector('.has-error');
//   const re = /\S+@\S+\.\S+/;

//   if (!re.test(input.value)) {
//     error.classList.add('has-error--show');
//   } else {
//     error.classList.remove('has-error--show');
//   }

//   return re.test(input.value);
// }

// function submitEmail(ev, input) {
//   ev.preventDefault();
//   if (wrongEmail(input)) {
//     localStorage.setItem('email', input.value);
//     location.href = '/sweepstakes/';
//   }
// }

// void function goToSweepStake() {
//   const input = document.querySelector('#email');
//   const submit = document.querySelector('#enter-sweeps .button');

//   submit.addEventListener('click', ev => submitEmail(ev, input));
// }();





// document.addEventListener('DOMContentLoaded', function () {
//   const form = document.querySelector('.form-test');

//   form.addEventListener('submit', function(event) {
//       // event.preventDefault();  // Prevent the actual form submission

//       // Define the component to render
//       const ThankYouMessage = () => {
//           return <div className="thankyou-response">
//                     <p className="thankyou-response-title">Thank you for signing up!</p>
//                     <p className="thankyou-response-paragraph">
//                       You will receive a confirmation email shortly. Please make sure
//                       you click on the “VERIFY” button to guarantee delivery.
//                     </p>
//                   </div>;
//         };

//       // Use ReactDOM to render the thank you message into the root container
//       ReactDOM.render(<ThankYouMessage />, document.getElementById('home-news-form'));
//   });
// });