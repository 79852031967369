console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
let actualHour = new Date();
console.log("time",actualHour.getTime());

const homeTopSlides = {
  slides: [
    {
      caption: "Resorts For Everyone", 
      link: "/resorts/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_RFE_HpBanner_Desktop_Oct2024.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_RFE_HpBanner_Mobile_Oct2024.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "Elf on the Shelf",
      link: "/the-elf-on-the-shelf/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/elf_on_shelf_hero_desk.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/elf_on_shelf_hero_mob.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "All-New Beachfront Villas & Penthouse Collection",
      link: "/resorts/negril/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_New-Accommodations-HP.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_New-Accommodations-HP.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "3 New Resorts In Jamaica",
      link: "/jamaica/opening-new-resorts/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/3NewJamaicaResorts/July2021/3NewJamaicaResorts-Desktop.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/3NewJamaicaResorts/July2021/3NewJamaicaResorts-Desktop.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "World’s Leading All-Inclusive Family Resorts",
      link: "/deals/uk-offers/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_EU_DesktopBanner_v2.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_EU_DesktopBanner_v2.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "Something to look forward to in 2024 and beyond.",
      link: "https://booking.beaches.co.uk/search",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_Brand-you-can-trust-_WebBanner_1920x1080.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/UK/Beaches_Brand-you-can-trust-_WebBanner_1920x1080.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: " ",
      link: "/celebrations/promotions/",
      img: "//cdn.sandals.com/beaches/v12/images/home/top-slides/sweetheartDeal_2024_desk3.jpg",
      mobimg: "//cdn.sandals.com/libs/ui/brands/assets/uploads/Beaches_weddings_mobile_de67a99aad.png",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
  ],
};

module.exports = homeTopSlides;
